(function( $ ) {

    $.fn.floatHeader = function() {

        var jqAllAffectedContainers = this;

        jqAllAffectedContainers.each(function() {
            var jqContainer = $(this);
            var screenWidth = window.innerWidth
                || document.documentElement.clientWidth
                || document.body.clientWidth;
            var top  = window.pageYOffset || document.documentElement.scrollTop;
            var left = window.pageXOffset || document.documentElement.scrollLeft;
            var screenOnTop = (top === 0);
            if (screenWidth <= 1279) {
                jqContainer.removeClass('float');
                return false;
            }
            if (screenOnTop) {
                jqContainer.removeClass('float');
            }
            if (top > 95) {
                jqContainer.addClass('float');
            }
        });

    };

}( jQuery ));

document.addEventListener('DOMContentLoaded', function() {
    $('.float-header').floatHeader();
});

window.onscroll = function (e) {
    $('.float-header').floatHeader();
};
